section {
    padding: 20px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 5px;
}

section h1 {
    margin: 10px 0;
    color: #777777;
}


.box {
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}

.box > p {
  color: #777777;
  border-radius: 5px;
}

.box .comments {
  display: flex;
  font-size: 14px;
  padding: 10px;
  color: #777777;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  form {
    display: flex;
    align-items: flex-start;
    margin-right: 50px;
    * { margin-right: 10px; }
  }
}

.resultsBox {
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}

.resultsBox > h2 {
  color: #1A5276;
  border-radius: 5px;
}


/* Customize the label (the container) */
.NewLic {
  display: block;
  cursor: pointer;
  margin: 10px 0;
  color: #777777;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
