section {
    padding: 20px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 5px;
}

section h1 {
    margin: 10px 0;
    color: #777777;
}

.box {
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}

.box > p {
  color: #777777;
  border-radius: 5px;
}

.resultsBox {
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
  margin-top: 20px;
}

.resultsBox > h1 {
  color: #777777;
  border-radius: 5px;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}


/* Customize the label (the container) */
.LicLook {
  display: block;
  cursor: pointer;
  color: #777777;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Smaller  {
  color: #6F6F6F;
  font-size: 14px;
  margin-top: 10px;
}
