.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #777777;
}

.Home .lander p {
  color: #999;
}

.Home .keys h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #777777;
}
.Home .keys h1 {
  color: #777777;
  font-weight: bold;
  font-size: 24px;
}

.Home .expire p {
  color: #777777;
  font-weight: bold;
  font-size: 24px;
}

.Home .expire h3 {
    color: #ed7979;
    font-weight: bold;
    font-size: 16px;
}
