section {
    padding: 20px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 5px;
}

section h1 {
    margin: 10px 0;
    color: #777777;
}

/* Customize the label (the container) */
.NewLicFull {
  display: block;
  cursor: pointer;
  color: #777777;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box {
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}

.box > p {
  color: #777777;
  border-radius: 5px;
}

.resultsBox {
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}

.resultsBox > h2 {
  color: #1A5276;
  border-radius: 5px;
}


.FullLic9 {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}

.FullLic9 > p {
  color: #777777;
}

.FullLic8 {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
}

.FullLic8 > p {
  color: #777777;
}
